'use client';

import { memo, useEffect, useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { Toast } from '@core/Components/Toast/Toast';
import { toastManagerAtom } from '@core/Components/Toast/ToastManager';
import Button from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily, activeAccModalAtom } from '../../Atoms/AccModal.atom';
import { AccountFormFields } from '../AccountFormData/AccountFormFields';
import { loginFormConfig } from '../AccountFormData/LoginFormConfig';

import { AccountFormDefaultMessages } from './AccountForm.messages';
import { useAccountForm } from './Hooks/UseAccountForm';
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccLoginForm = memo(({ strings }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { replace } = useRouter();
  const [isPostVerification, setIsPostVerification] = useState(false);
  const isLoginModalOpen = useAtomValue(accModalAtomFamily('login'));
  const setActiveAccModal = useSetAtom(activeAccModalAtom);
  const addToast = useSetAtom(toastManagerAtom);

  const { formRef, handleSubmit, generalFormError, isLoading } = useAccountForm({
    url: '/api/border-control/auth/login',
    sendAsFormData: true,
    successModalId: null,
    successUrl: '/account',

    onSuccess: () => {
      addToast({ children: strings.loginSuccessful, variant: 'dark' });
    },
  });

  useEffect(() => {
    if (searchParams.get('isPostVerification') == 'true') setIsPostVerification(true);
    if (searchParams.get('login') === 'show') {
      const email = searchParams.get('email');
      const emailRef = formRef?.current?.elements.namedItem('email') as HTMLInputElement;
      emailRef?.setAttribute('value', email!);

      setActiveAccModal('login');
      replace(pathname);
    }
  }, [pathname]);

  return (
    <div className={styles.overlay} data-visible={isLoginModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.myHeycarAccount}</Typography>
          <button className={styles.closeButton} onClick={() => setActiveAccModal(null)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.userIconAlt} />
          <Typography variant="body1">{strings.signInTrackSearch}</Typography>

          {isPostVerification && (
            <Typography Component="span" variant="caption1">
              {strings.verifiedMessage}
            </Typography>
          )}

          <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
            <AccountFormFields strings={strings} config={loginFormConfig} formEl={formRef} />

            <Typography
              Component="span"
              variant="caption1"
              className={styles.forgot}
              onClick={() => setActiveAccModal('forgotPassword')}
            >
              {strings.forgotPassword}
            </Typography>
            <Button loading={isLoading} fullWidth>
              {strings.signIn}
            </Button>
            <Toast variant="error">{generalFormError}</Toast>
          </form>

          <Typography
            variant="body1"
            onClick={() => setActiveAccModal('signup')}
            className={styles.switch}
          >
            {strings.noAccountYet} <span>{strings.signUpHere}</span>
          </Typography>

          {/* <div className={styles.divider}>
            <span>Or</span>
          </div> */}

          {/* <div className={styles.buttons}> */}
          {/* <Button variant="outlined" fullWidth>
              <img src={googleIcon.src} alt="Google icon" />
              Continue with Google
            </Button> */}
          {/* <Button variant="outlined" fullWidth>
              <img src={facebookIcon.src} alt="Facebook icon" />
              Continue with Facebook
            </Button>
            <Button variant="outlined" fullWidth>
              <img src={appleIcon.src} alt="Apple icon" />
              Continue with Apple
            </Button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
});

AccLoginForm.displayName = 'AccLoginForm';
