import { UK_PHONE_VALIDATION, FR_PHONE_VALIDATION } from '@core/Constants/Validations';
import { validatePostcode } from '@core/Utils/Validation/ValidatePostcode';

import { FormConfig } from './FormConfig.entity';

export const confirmDetailsFormConfig: FormConfig = {
  id: 'confirmDetails',

  fields: [
    {
      id: 'firstName',
      type: 'text',
      name: 'firstName',
      placeholder: ({ strings }) => strings?.firstName,
      validate: ({ value, strings }) => {
        if (value?.length < 3) return strings.requiredField;
      },
    },
    {
      id: 'lastName',
      type: 'text',
      name: 'lastName',
      placeholder: ({ strings }) => strings?.lastName,
      validate: ({ value, strings }) => {
        if (value?.length < 3) return strings.requiredField;
      },
    },
    {
      id: 'phoneNumber',
      type: 'text',
      name: 'phoneNumber',
      placeholder: ({ strings }) => strings?.phoneNumber,
      validate: ({ value, strings, locale }) => {
        let regex: RegExp = UK_PHONE_VALIDATION;
        if (locale === 'fr') regex = FR_PHONE_VALIDATION;

        if (!value) return '';
        if (!regex?.test(value)) return strings.invalidField;
      },
    },
    {
      id: 'postcode',
      type: 'text',
      name: 'postcode',
      placeholder: ({ strings }) => strings?.postcode,
      transformValue: ({ value }) => value?.toUpperCase?.(),
      validate: async ({ value, strings, locale }) => {
        if (!value) return '';

        try {
          const res = await validatePostcode(value, locale);

          if (!res?.isValid) return strings.invalidField;
        } catch (err) {
          return strings.invalidField;
        }
      },
    },
  ],
} as const satisfies FormConfig;
